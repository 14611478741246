<template>
  <mappo></mappo>
</template>

<script>
import Mappo from './components/Map.vue'

export default {
  name: 'Wiener',
  components: {
    Mappo
  }
}
</script>

<style>
body {
  margin: 0;
}
</style>
