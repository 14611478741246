<template>
  <div class="container">
    <div v-if="showPopup" class="popup">
      <input type="text" v-model="marker.name">
      <div class="actions">
        <button class="regret" @click="reset">Regret</button>
        <button @click="addMarker">Add</button>
      </div>
    </div>
    <v-touch v-on:press="openPopup" v-bind:press-options="{ time: 1000 }">
      <div id="map"></div>
    </v-touch>
  </div>
</template>

<script>
import firebase from 'firebase'
import L from 'leaflet'
import overlay from '../static/map.jpg'
import icon from '../static/marker.svg'
import shadow from '../static/marker-shadow.svg'

const db = firebase
  .initializeApp({
    apiKey: 'AIzaSyBbuEe808YjDP4kCzGgDPRkHeYRZ3u0_TM',
    authDomain: 'djupt-vatten.firebaseapp.com',
    databaseURL: 'https://djupt-vatten.firebaseio.com',
    projectId: 'djupt-vatten',
    storageBucket: 'djupt-vatten.appspot.com',
    messagingSenderId: '913897703749'
  })
  .database()
  .ref('markers')

export default {
  data: () => ({
    width: 2000,
    height: 3012,
    map: undefined,
    icon: undefined,
    coordinates: undefined,
    marker: {
      name: '',
      coordinates: undefined
    },
    markers: [],
    showPopup: false
  }),

  methods: {
    addEventListeners() {
      this.map.addEventListener(
        'mousedown',
        ({ latlng }) => (this.coordinates = latlng)
      )
      db.on('value', markers =>
        markers.forEach(m => this.renderMarker(m.val()))
      )
    },
    openPopup() {
      const { lat, lng } = this.coordinates
      this.marker.coordinates = [lat, lng]
      this.showPopup = true
    },
    addMarker() {
      db.push({
        title: this.marker.name,
        coordinates: this.marker.coordinates
      })
      this.reset()
    },
    renderMarker(m) {
      const { coordinates, title, link } = m
      const marker = L.marker(coordinates, { icon: this.icon })
      marker.bindPopup(
        link
          ? `<a href="${link}" title="${title}" target="_blank">${title}</a>`
          : `<p>${title}</p>`
      )
      marker.addTo(this.map)
    },
    reset() {
      this.showPopup = false
      this.marker.name = ''
      this.marker.coordinates = undefined
    }
  },

  mounted() {
    // Setup map
    this.map = L.map('map', {
      minZoom: 1,
      maxZoom: 3,
      zoom: 1,
      center: [0, 0],
      crs: L.CRS.Simple
    })

    const southWest = this.map.unproject(
      [0, this.height],
      this.map.getMaxZoom() - 2
    )
    const northEast = this.map.unproject(
      [this.width, 0],
      this.map.getMaxZoom() - 2
    )
    const bounds = new L.LatLngBounds(southWest, northEast)

    L.imageOverlay(overlay, bounds).addTo(this.map)

    this.map.setMaxBounds(bounds)
    this.map.setView([southWest.lat / 2, northEast.lng / 2])

    // Setup custom marker icon
    this.icon = L.icon({
      iconUrl: icon,
      iconSize: [36, 36],
      iconAnchor: [18, 35],
      popupAnchor: [0, -31],
      shadowUrl: shadow,
      shadowSize: [36, 38],
      shadowAnchor: [2, 37]
    })

    this.addEventListeners()
  }
}
</script>

<style scoped>
.container {
  position: relative;
}

.popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-radius: 4px;
  background: rgb(221, 221, 221);
  z-index: 1000;
}

input {
  border: 1px solid rgb(25, 25, 25);
  margin-bottom: 5px;
  padding: 5px;
}

.actions {
  display: flex;
}

button {
  border: none;
  background: rgb(25, 25, 25);
  color: rgb(221, 221, 221);
  padding: 5px;
  flex-basis: 50%;
  cursor: pointer;
}

.regret {
  background: none;
  color: rgb(25, 25, 25);
}

#map {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
</style>
